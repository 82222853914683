<template>
  <div class="profile">
    <div class="container">
      <div class="nameList">
        <h3>{{formList.firstname}}{{formList.lastname}}</h3>
        <p>{{formList.mail}}</p>
      </div>
      <div class="card">
        <div class="card-header">
          <i class="el-icon-s-unfold"></i>
          <span>Profile</span>
        </div>

        <div class="card-body">
          <el-form :label-position="labelPosition"
                   label-width="80px"
                   :model="formLabelAlign"
                   :rules="rules"
                   ref="formLabelAlign">
            <el-form-item label="Email address">
              <el-input v-model="formList.mail"
                        :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="Firstname">
              <el-input v-model="formList.firstname"
                        :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="LastName">
              <el-input v-model="formList.lastname"
                        :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="Country">
              <el-input v-model="formList.country"
                        :disabled="true"
                        placeholder="US"></el-input>
            </el-form-item>
            <el-form-item label="New Password"
                          prop="pass">
              <el-input type="password"
                        v-model="formLabelAlign.pass"
                        autocomplete="off"
                        placeholder="Leave it blank if no change"></el-input>
            </el-form-item>
            <el-form-item label="Confirm Password"
                          prop="checkPass">
              <el-input type="password"
                        v-model="formLabelAlign.checkPass"
                        autocomplete="off"
                        placeholder="Leave it blank if no change"></el-input>
            </el-form-item>
            <el-form-item label="Amazon Profile URL">
              <el-input v-model="formList.amazon_profile_url"
                        :disabled="true"></el-input>
              <el-link type="primary"
                       @click="centerDialogVisible = true">How to find amazon profile URL?</el-link>
              <el-dialog title="How to find amazon profile URL?"
                         :visible.sync="centerDialogVisible"
                         :append-to-body="true"
                         width="30%"
                         center>
                <div class="amount">
                  <p>1. Log into your Amazon account.</p>
                  <p>
                    2. Click the
                    <strong>"Your Account"</strong> from the top menu.
                  </p>
                  <p>
                    3. Scroll down until you see the
                    <strong>"Ordering and shopping preferences "</strong> sub section.
                  </p>
                  <p>
                    4. Click on
                    <strong>"Profile"</strong>
                  </p>
                  <p>5. Once you're on the profile page, copy the link (URL) from your internet browser.</p>
                  <p>
                    <strong>Note: Please fill in carefully, once you fill in, you can't modify it.</strong>
                  </p>
                </div>
                <span slot="footer"
                      class="dialog-footer">
                  <el-button @click="centerDialogVisible = false"
                             size="mini"
                             class="close">close</el-button>
                </span>
              </el-dialog>
              <p>(Please fill in carefully, once you fill in, you can't modify it.)</p>
            </el-form-item>
            <el-form-item label="Presentation">
              <el-input v-model="formList.presentation"
                        type="textarea"
                        :rows="2"></el-input>
            </el-form-item>
            <el-form-item label="Paypal Account">
              <el-input v-model="formList.paypal_account"></el-input>
            </el-form-item>
            <el-form-item label="Social Page">
              <el-input v-model="formList.social_page"></el-input>
            </el-form-item>
          </el-form>
          <el-button type="primary"
                     size="small"
                     :loading="$store.state.loadingBool"
                     @click="updateUser">Edit Profile</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 导入个人信息修改接口
import {
  userQuery // 查询个人资料
} from '../../api/buyerFront'
import {
  updetaUser // 用户修改个人信息
} from '../../api/buyerAfter'
// 导入token
import { getToken, removeToken } from '../../utils/token'
export default {
  data () {
    // 密码验证
    var validatePass = (rule, value, callback) => {
      const passReg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/
      if (value === '') {
        callback(new Error('请输入密码'))
      } else if (!passReg.test(value)) {
        callback(new Error('密码至少包含 数字和英文，长度6-20'))
      } else {
        if (this.formLabelAlign.checkpass !== '') {
          this.$refs.formLabelAlign.validateField('checkPass')
        }
        callback()
      }
    }
    // 确认密码
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.formLabelAlign.pass) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }

    return {
      centerDialogVisible: false,
      labelPosition: 'top',
      formList: {},
      formLabelAlign: {
        // mail: '',
        // firstname: '',
        // lastname: '',
        pass: '',
        checkPass: ''
        // country: '',
        // amazon: '',
        // presentation: '',
        // paypal: '',
        // social: ''
      },

      rules: {
        pass: [{ validator: validatePass, trigger: 'blur' }],
        checkPass: [{ validator: validatePass2, trigger: 'blur' }]
      }
    }
  },
  created () {
    this.$store.commit('getLoading', false)
    const token = getToken()
    if (token) {
      // 查询个人资料
      userQuery().then(result => {
        if (result.data.code === 200) {
          // console.log(result)
          this.formList = result.data.data
          // this.formList.country = this.formList.country.split('-')[3]
          // (this.formList)
        }
        // ('result =>', result)
      }).catch(err => {
        return err
      })
    } else {
      this.$router.push({ path: '/F_login' })
    }
  },
  methods: {
    // 修改用户信息
    updateUser () {
      this.$store.commit('getLoading', true)
      // (this.formLabelAlign)
      if (this.formLabelAlign.pass !== '' || this.formLabelAlign.checkPass !== '') {
        this.$message.error('Please enter the full password')
        this.$store.commit('getLoading', false)
      } else {
        updetaUser({
          amazon_profile_url: this.formList.amazon_profile_url,
          creation_time: this.formList.creation_time,
          id: this.formList.id,
          firstname: this.formList.firstname,
          lastname: this.formList.lastname,
          mail: this.formList.mail,
          password: this.formLabelAlign.pass,
          paypal_account: this.formList.paypal_account,
          presentation: this.formList.presentation,
          role: this.formList.role,
          social_page: this.formList.social_page,
          status: this.formList.status
        }).then(result => {
          if (result.data.code === 200) {
            this.$store.commit('getLoading', false)
            if (this.formLabelAlign.pass === '') {
              this.$message.success(result.data.message)
            } else {
              removeToken()
              this.$message.success(result.data.message + '请重新登录')
              this.$router.push({ path: '/F_login' })
            }
          } else {
            this.$store.commit('getLoading', false)
            this.$message.error(result.data.message)
          }
        }).catch(err => {
          return err
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.profile {
  text-align: left;
  .container {
    padding: 20px 30px;
    .nameList {
      background-color: #6f42c1;
      padding: 16px;
      margin-bottom: 15px;
      h3 {
        font-size: 26px;
        font-weight: 500;
        line-height: 1.2;
        color: #fff;
        margin-bottom: 5px;
      }

      p {
        color: white;
      }
    }

    .card {
      position: relative;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      min-width: 0;
      background-color: #fff;
      border: 1px solid #c8ced3;
      border-radius: 4px;

      .card-header {
        padding: 12px 20px;
        background-color: #05141f;
        border-bottom: 1px solid #c8ced3;
        color: white;
        font-weight: bolder;
        span {
          line-height: 19px;
          margin-left: 5px;
        }
      }

      .card-body {
        flex: 1 1 auto;
        padding: 20px;
      }
    }
  }
}
</style>
<style lang="less">
.profile {
  .el-dialog__header {
    border-bottom: 1px solid #c4c4c6;
    font-weight: bolder;
    text-align: left !important;
  }
  .el-dialog__body {
    border-bottom: 1px solid #c4c4c6;
  }
  .el-dialog__footer {
    text-align: right !important;
  }
  .close {
    background-color: #ccc;
  }
}
.amount {
  p {
    line-height: 20px;
  }
}
</style>
